<template>
  <v-row class="cw-business-loan-signees__signees">
    <v-col
      cols="12"
      md="5"
    >
      <h3 class="text-subtitle-1 font-weight-bold mb-1">
        <translate>Adding signatories</translate>
      </h3>
      <p class="ma-0">
        <translate>
          Add the loan application signatories with their contact details according to the business
          signature clause. Nominees will be notified by email of the application and a link to
          confirm the application will be sent to them. The application must be confirmed within
          7 days.
        </translate>
      </p>
    </v-col>

    <v-col
      id="table__signees"
      :class="{ 'error--text': error }"
      class="
        cw-border-container
        cw-border-container--primary
        cw-border-container--md-center
      "
      cols="12"
      md="7"
    >
      <div v-if="signingRights">
        <h3 class="text-subtitle-1 font-weight-bold mb-1">
          <translate>
            The business signature clause
          </translate>
        </h3>
        <p class="ma-0">
          {{ signingRights.procurationAbstractDescription }}
        </p>
      </div>
      <v-card-actions class="px-0">
        <h3 class="text-subtitle-1 font-weight-bold mb-1">
          <translate>
            Signees
          </translate>
        </h3>
        <v-spacer/>
        <v-btn
          id="open-dialog--signee"
          :disabled="$wait.waiting('signee__add') || !hasAvalableSignees"
          :loading="$wait.waiting('signee__add')"
          color="primary lighten-1"
          outlined
          small
          @click="
            onDialogOpen();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Add</translate>
        </v-btn>
      </v-card-actions>

      <v-alert
        :color="alert.color"
        :type="alert.type"
        :value="alert.show"
        transition="fade-transition"
        class="ma-0"
        dense
        tile
      >
        {{ alert.text }}
      </v-alert>

      <div class="cw-application__table">
        <table>
          <tbody>
            <template v-if="signees.length">
              <tr
                v-for="(signee, index) in signees"
                :id="`cw-business-loan-signees__table-row--signee-${index + 1}`"
                :key="signee.ssn"
                :class="{ 'error--text': !signee.valid }"
                class="cw-business-loan-signees__table-row--signee"
              >
                <td>{{ signee.name }}</td>
                <td v-if="signee.roles" class="text-right font-weight-bold">
                  <cw-chips :items="signee.roles" :error="!signee.valid"/>
                </td>
                <td width="24">
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        :id="`remove-signee__${signee.ssn}`"
                        color="grey darken-1"
                        text
                        icon
                        v-on="on"
                        @click="
                          onRemoveItem(signee);
                          $eventLogger.clickEvent($event);
                        "
                      >
                        <v-icon size="18">
                          delete
                        </v-icon>
                      </v-btn>
                    </template>
                    <span><translate>Delete</translate></span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="cw-business-loan-signees__table-row--no-data-signee">
                <td>
                  <translate>
                    No signers added.
                  </translate>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CwBusinessLoanSigneesSignees',

  components: {
    'cw-chips': () => import('@shared/components/Chips'),
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    validated: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      initialData: 'application/getInitialData',
      hasEnoughSignees: 'application/hasEnoughSignees',
      signees: 'application/signees',
    }),

    ...mapState({
      signingRights: state => state.application.signingRights,
    }),

    alert() {
      return {
        color: this.alertColor,
        show: this.showAlert,
        text: this.alertText,
        type: this.alertType,
      };
    },

    alertText() {
      const errorText = this.hasInvalidSigneeData
        ? this.$gettext('Incorrectly completed signer information')
        : this.$gettext('The signature clause is not fulfilled by the signatories given');

      return this.hasError ? errorText : this.$gettext('The signature clause Completed');
    },

    alertColor() {
      return this.hasError ? 'error' : 'primary';
    },

    alertType() {
      return this.hasError ? 'error' : 'info';
    },

    availableSignees() {
      const {
        form,
        signingRights: {
          signees,
        },
      } = this;

      if (!signees) return [];
      return signees
        .filter(s => !form.signees.map(signee => signee.ssn).includes(s.ssn));
    },

    hasAvalableSignees() {
      return this.availableSignees.length > 0;
    },

    error() {
      return this.validated && !this.hasEnoughSignees;
    },

    hasError() {
      return this.hasInvalidSigneeData || this.error;
    },

    hasInvalidSigneeData() {
      return this.signees.some(signee => signee.valid === false);
    },

    showAlert() {
      return this.hasInvalidSigneeData || this.hasEnoughSignees || this.error;
    },
  },

  methods: {
    onDialogOpen() {
      const {
        $bus,
        availableSignees,
        signingRights: {
          procurationAbstractDescription,
        },
        signees,
      } = this;

      $bus.$emit('SIGNEES_DIALOG_OPEN', {
        type: 'signee',
        data: {
          index: signees.length,
          availableSignees,
          procurationAbstractDescription,
        },
      });
    },

    onRemoveItem(item) {
      this.$bus.$emit('SIGNEES_ITEM_REMOVE', { item, type: 'signee' });
    },
  },
};
</script>

<style lang="scss">
.cw-business-loan-signees__signees {
  .cw-application__table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
